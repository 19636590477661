<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

interface Option {
    label: string
    value: string
}

interface Props {
    options: Option[]
    placeholder?: string
    modelValue?: Option | null
    top?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits(['update:modelValue'])

const target = ref(null)
const selectedOption = ref<Option | null>(props.modelValue || null);
const isOpen = ref(false);

onClickOutside(target, event => isOpen.value = false)

const toggleDropdown = () => {
    isOpen.value = !isOpen.value;
};

const selectOption = (option: Option) => {
    selectedOption.value = option;
    emit('update:modelValue', selectedOption.value)
    isOpen.value = false
};
</script>

<template>
    <div class="ui-select" ref="target">
        <div class="ui-select-option" @click="toggleDropdown">
            <slot>
                {{ selectedOption?.label || selectedOption || placeholder }}
            </slot>
            <SvgoChevronDown :class="['chevron', { 'chevron-opened': isOpen }]" />
        </div>
        <ul v-if="isOpen" class="ui-select__dropdown" :class="{ 'ui-select__dropdown-top': top }">
            <li v-for="(option, index) in options" :key="index" @click="selectOption(option)" :class="{ 'selected': option === selectedOption }">
                <slot name="option" :option="option">{{ option.label || option }}</slot>
            </li>
        </ul>
    </div>
</template>

<style scoped lang="scss">
.ui-select {
    @apply w-full relative select-none;
    &-option {
        @apply relative cursor-pointer px-4 pr-8 py-[10px] border border-gray-300 bg-gray-50 text-gray-500 rounded-lg text-sm;
        .chevron {
            @apply w-[10px] absolute top-[calc(50%-2px)] right-3 duration-200;
            &-opened {
                @apply rotate-180;
            }
        }
    }
    &__dropdown {
        @apply absolute top-[calc(100%+8px)] left-0 right-0 shadow-md bg-white rounded-lg z-10 overflow-auto max-h-40;
        li {
            @apply px-4 py-2 cursor-pointer hover:bg-gray-100;
            &.selected {
                @apply bg-gray-100;
            }
        }
        &-top {
            @apply top-[calc(-100%-40px)];
        }
    }
}
</style>